import React, { useEffect, useState } from 'react';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { Box, Button, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { addDays, addMonths } from 'date-fns';
import 'react-multi-carousel/lib/styles.css';

// project import
import BacterialLevelChart from './components/BacterialLevelChart';
import Calendar from '../../components/RDR';
import { dateActions, intTempStats } from './utils';
import MainCard from './../../components/MainCard';
import OverviewBox from '../../components/OverviewBox';
import { fontStyle } from './utils';
import CustomSwiper from './swiper';
import { useSelector } from 'react-redux';
import moment from 'moment';
import { getAuthToken } from '../../utils/jwtUtils';

// ==============================|| DASHBOARD - DEFAULT ||============================== //

function Dashboard() {
  const token = getAuthToken();
  const { t } = useTranslation();
  const { currentDevice } = useSelector((state) => state.device);
  const [tempStats, setTempStats] = useState(intTempStats);
  const [data, setData] = useState([]);

  // Function to handle onChange for select field
  const [searchData, setSearchData] = useState({
    startDate: addMonths(new Date(), -1),
    endDate: new Date(),
    dateOption: dateActions.ONE_MONTH,
  });

  const handleDateChange = (e) => {
    const { value } = e.target;

    let startDate, endDate;

    switch (value) {
      case dateActions.TODAY:
        startDate = endDate = new Date();
        break;
      case dateActions.THREE_DAYS:
        startDate = addDays(new Date(), -3);
        endDate = new Date();
        break;
      case dateActions.ONE_MONTH:
        startDate = addMonths(new Date(), -1);
        endDate = new Date();
        break;
      case dateActions.THREE_MONTHS:
        startDate = addMonths(new Date(), -3);
        endDate = new Date();
        break;
      default:
        break;
    }

    setSearchData({ ...searchData, startDate, endDate, dateOption: value });
  };

  const handleChangeInput = (e) => {
    const { name, value } = e.target;
    if (name === 'searchDate' && value?.key === 'selection') {
      setSearchData({
        ...searchData,
        startDate: value?.startDate,
        endDate: value?.endDate,
        dateOption: '',
      });
    }
  };

  const fetchSensorStats = async () => {
    const startDate = moment(searchData.startDate).format('YYYY-MM-DD');
    const endDate = moment(searchData.endDate).format('YYYY-MM-DD');
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/sensors/all`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate,
          endDate,
          nodeId: currentDevice?.nodeId,
        }),
      });

      const data = await response.json();

      // Check for errors or no data after parsing JSON
      if (!response.ok || (data.message && data.message === 'No culturing AQI found for this month!')) {
        // Handle no data case by explicitly clearing the chart data
        setData([]);  // Clear chart data by setting an empty array
        return;
      }

      // Set the chart data if data is available
      setData(data);

    } catch (error) {
      // Handle fetch error by clearing the data
      setData([]);  // Clear chart data in case of error
    }
  };

  const fetchTempStats = async () => {
    try {
      const response = await fetch(`${process.env.REACT_APP_SERVER_URL}/sensors/tempStats`, {
        method: 'POST', // Use POST method
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
        body: JSON.stringify({
          startDate: moment(searchData.startDate).format('YYYY-MM-DD'),
          endDate: moment(searchData.endDate).format('YYYY-MM-DD'),
          nodeId: currentDevice.nodeId,
        }),
      });
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const data = await response.json();

      // Process and map the data to the tempStats structure
      const updatedStats = [
        {
          title: 'avg_temperature_level',
          value: data[0].tempAvg,
        },
        {
          title: 'max_temperature_level',
          value: data[0].tempMax,
        },
        {
          title: 'min_temperature_level',
          value: data[0].tempMin,
        },
      ];

      // Update the state with the new stats
      setTempStats(updatedStats);
    } catch (error) {
      console.error('Failed to fetch data:', error);
    }
  };

  const handleSearch = () => {
    fetchTempStats();
    fetchSensorStats();
  };

  useEffect(() => {
    fetchTempStats();
    fetchSensorStats();
  }, [t])

  return (
    <Grid container rowSpacing={2.5} columnSpacing={2.75} sx={{ mb: 1 }}>
      <Grid item xs={12} sx={{ mb: -2.25 }}>
        <Box
          sx={{
            display: 'flex',
            flexDirection: { xs: 'column', sm: 'row' },
            gap: 2,
            pb: 1.5,
            alignItems: 'center',
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            <Typography
              sx={{
                borderRight: '0px !important',
                color: 'black',
                fontWeight: 'fontWeightBold',
                fontSize: 13,
                whiteSpace: 'nowrap',
              }}
            >
              {t('period')}
            </Typography>

            <ToggleButtonGroup
              size='small'
              color='secondary'
              sx={{
                fontWeight: 'bold',
                display: 'block',
              }}
              value={searchData?.dateOption}
              exclusive
              onChange={handleDateChange}
              aria-label='defined date range'
            >
              {Object.keys(dateActions).map((key, index) => (
                <ToggleButton
                  key={`${index}-${dateActions[key]}`}
                  value={dateActions[key]}
                  aria-label='left aligned'
                  sx={{
                    color: 'black !important',
                    fontWeight: dateActions[key] === searchData.dateOption ? 'bold' : 400,
                    minWidth: '60px',
                    borderRadius: 0,
                    '&:focus': {
                      backgroundColor: 'lightblue',
                    },
                  }}
                >
                  {t(key)}
                </ToggleButton>
              ))}
            </ToggleButtonGroup>
          </Box>

          <Calendar
            state={{
              startDate: searchData.startDate,
              endDate: searchData.endDate,
              key: 'selection',
            }}
            dateRangeProps={{
              minDate: new Date('2017-08-01'),
              maxDate: new Date(),
            }}
            setState={(e) => handleChangeInput({ target: { name: 'searchDate', value: e } })}
          />

          {/* Btn */}
          <Button
            variant='contained'
            disabled={!searchData?.startDate || !searchData.endDate}
            onClick={handleSearch}
            sx={{
              width: 100,
              height: '100%',
              borderRadius: 3,
              m: 0,
            }}
          >
            {t('search')}
          </Button>
        </Box>
      </Grid>

      {/* Row for OverviewBoxes */}
      <OverviewBox data={tempStats} />

      {/* Bacterial Line and Pie chart */}
      <Grid item xs={12}>
        <Grid container alignItems='center' justifyContent='space-between'>
          <Grid item>
            <Typography sx={{ ...fontStyle }} variant='h5'>
              {t('temperature')}
            </Typography>
          </Grid>
        </Grid>
        <MainCard sx={{ mt: 1.5 }} content={false}>
          <Box sx={{ pt: 1, pr: 2, height: '400px' }}>
            <BacterialLevelChart slot={'day'} data={data} />
          </Box>
        </MainCard>
      </Grid>

      {/* Sensor Line Charts in swiper caraousel */}
      <Grid item sx={{ mt: 1 }} xs={12}>
        <Grid item sx={{ mb: 1.5 }}>
          <Typography sx={{ ...fontStyle }} variant='h5'>
            {t('air_quality')}
          </Typography>
        </Grid>
        <CustomSwiper data={data} />
      </Grid>
    </Grid>
  );
}

export default Dashboard;
