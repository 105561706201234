import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { setAlert } from '../redux/alert/alertSlice';
import { getAuthToken } from '../utils/jwtUtils';
import { FormControl, MenuItem, Select } from '@mui/material';
import { fontStyle } from '../pages/dashboard/utils';
import { useTranslation } from 'react-i18next';
import { setDevice } from '../redux/device/deviceSlice';
import { useSelector } from 'react-redux';

function SelectMenu() {
  const { t } = useTranslation();
  const { currentUser } = useSelector((state) => state.user);
  const { currentDevice } = useSelector((state) => state.device);

  const [facilities, setFacilities] = useState([]);
  const dispatch = useDispatch();
  const token = getAuthToken();

  const handleChange = (e) => {
    dispatch(setDevice(facilities.find((facility) => facility.nodeId == e.target.value)));
  };

  const fetchDevices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/devices/all`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });
      const data = await res.json();

      if (!res.ok) {
        dispatch(setAlert({ type: 'error', message: data.message }));
        return;
      }
      setFacilities(data);
      // set the default if none exists
      if (data.length > 0 && !currentDevice?.nodeId) {
        dispatch(setDevice(data[data.length - 1]));
      }
    } catch (error) {
      dispatch(setAlert({ type: 'error', message: error.message }));
    }
  };

  useEffect(() => {
    fetchDevices();
  }, [dispatch, token]);

  return (
    <FormControl sx={{ minWidth: 150 }} size='small'>
      {facilities.length > 0 && (
        <Select
          id='demo-select-small'
          value={currentDevice.nodeId}
          onChange={handleChange}
          sx={{ ...fontStyle, fontSize: '0.8rem', mx: 2 }}
        >
          {facilities.map((select, i) => (
            <MenuItem sx={{ ...fontStyle }} key={i} value={select.nodeId}>
              {t(select.nodeName)}
            </MenuItem>
          ))}
        </Select>
      )}
    </FormControl>
  );
}

export default SelectMenu;
