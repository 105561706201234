import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Logo from '../../Logo.png';
import { useState } from 'react';
import { signInFailure, signInStart, signInSuccess } from '../../redux/user/userSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { setAlert } from '../../redux/alert/alertSlice';
import { openPopup } from '../../redux/popup/popupSlice';
import Spinner from '../../components/Spinner';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

function AuthRegister() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { loading } = useSelector((state) => state.user);
  const [errorMessage, setErrorMessages] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    phoneNumber: '',
    companyName: '',
    general: '',
  });

  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    passwordConfirm: '',
    remember: '',
    phoneNumber: '',
    companyName: '',
  });

  const [showPassword, setShowPasswords] = useState({
    password: false,
    passwordConfirm: false,
  });

  const togglePasswordShow = (type) => {
    if (type === 'password') setShowPasswords((prev) => ({ ...prev, password: !prev.password }));
    if (type === 'passwordConfirm') setShowPasswords((prev) => ({ ...prev, passwordConfirm: !prev.passwordConfirm }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      dispatch(signInStart());
      setErrorMessages({});
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/register`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          name: formData.name,
          email: formData.email,
          phoneNumber: formData.phoneNumber,
          companyName: formData.companyName,
          password: formData.password,
          passwordConfirm: formData.passwordConfirm,
        }),
      });

      const data = await res.json();

      if (!res.ok) {
        dispatch(signInFailure(data.message || 'Registration failed'));
        switch (data.message) {
          case 'emptyNameMessage':
          case 'invalidNameMessage':
          case 'sizeNameMessage':
            setErrorMessages((prev) => ({ ...prev, name: data.message }));
            break;

          case 'emptyEmailMessage':
          case 'invalidEmailMessage':
          case 'sizeEmailMessage':
          case 'emailExistMessage':
            setErrorMessages((prev) => ({ ...prev, email: data.message }));
            break;

          case 'emptyPasswordMessage':
          case 'invalidPasswordMessage':
          case 'sizePasswordMessage':
            setErrorMessages((prev) => ({ ...prev, password: data.message }));
            break;

          case 'emptyPasswordConfirmMessage':
          case 'passAndConfirmPassNoMatchMessage':
            setErrorMessages((prev) => ({ ...prev, passwordConfirm: data.message }));
            break;

          case 'emptyPhoneMessage':
          case 'invalidPhoneMessage':
          case 'sizePhoneMessage':
            setErrorMessages((prev) => ({ ...prev, phoneNumber: data.message }));
            break;

          case 'emptyCompanyMessage':
          case 'invalidCompanyMessage':
          case 'sizeCompanyMessage':
            setErrorMessages((prev) => ({ ...prev, companyName: data.message }));
            break;

          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        return;
      }

      dispatch(
        openPopup({
          title: '회원가입 성공',
          message: t('sucRegUserMessage'),
        })
      );
      navigate('/login');
      dispatch(signInSuccess());
    } catch (err) {
      dispatch(signInFailure(err.message));
      dispatch(
        setAlert({
          type: 'error',
          message: err.message || 'Something went wrong, Please try again with correct values!',
        })
      );
    }
  };

  return (
    <Box
      component='main'
      sx={{
        maxWidth: '100vw',
        height: '100vh',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        overflow: 'auto',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyItems: 'center',
          alignItems: 'center',
          maxWidth: 450,
          my: 4,
          px: 2,
          mx: 'auto',
        }}
      >
        {loading && <Spinner />}
        <Box
          component='img'
          sx={{
            height: 64,
          }}
          alt='Logo'
          src={Logo}
        />
        <Typography variant='h1' sx={{ my: 1, fontWeight: 'bold', fontSize: '1.1rem' }}>
          스마트 간판 관리 통합 플랫폼
        </Typography>

        <Typography level='body-sm'>세션 시작을 위해 가입 해주세요</Typography>
        {errorMessage.general && (
          <Stack sx={{ width: '100%', mt: 1 }}>
            <Alert severity='error'>{t(errorMessage.general)}</Alert>
          </Stack>
        )}
        <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            id='name'
            label='이름'
            type='text'
            name='name'
            value={formData?.name}
            onChange={handleChange}
            autoFocus
            error={!!errorMessage.name}
            helperText={errorMessage.name ? t(errorMessage.name) : ''}
          />
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            id='email'
            type='email'
            label='이메일 주소'
            name='email'
            value={formData?.email}
            onChange={handleChange}
            error={!!errorMessage.email}
            helperText={errorMessage.email ? t(errorMessage.email) : ''}
          />
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            id='phoneNumber'
            label='전화 번호'
            type='text'
            name='phoneNumber'
            value={formData?.phoneNumber}
            onChange={handleChange}
            error={!!errorMessage.phoneNumber}
            helperText={errorMessage.phoneNumber ? t(errorMessage.phoneNumber) : ''}
          />
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            id='companyName'
            label='회사 이름'
            type='text'
            name='companyName'
            value={formData?.companyName}
            onChange={handleChange}
            error={!!errorMessage.companyName}
            helperText={errorMessage.companyName ? t(errorMessage.companyName) : ''}
          />
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            name='password'
            label='비밀번호'
            id='password'
            value={formData?.password}
            onChange={handleChange}
            error={!!errorMessage.password}
            helperText={errorMessage.password ? t(errorMessage.password) : ''}
            type={showPassword.password ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password' edge='end' onClick={() => togglePasswordShow('password')}>
                    {showPassword.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <TextField
            sx={{ marginY: { xs: 0.8, sm: 1.5 } }}
            required
            fullWidth
            name='passwordConfirm'
            label='비밀번호 확인'
            id='passwordConfirm'
            value={formData?.passwordConfirm}
            onChange={handleChange}
            error={!!errorMessage.passwordConfirm}
            helperText={errorMessage.passwordConfirm ? t(errorMessage.passwordConfirm) : ''}
            type={showPassword.passwordConfirm ? 'text' : 'password'}
            InputProps={{
              endAdornment: (
                <InputAdornment position='end'>
                  <IconButton
                    aria-label='toggle password'
                    edge='end'
                    onClick={() => togglePasswordShow('passwordConfirm')}
                  >
                    {showPassword.passwordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />

          <Box>
            <Link href='/login' variant='body2'>
              {'이미 계정이 있나요? 로그인'}
            </Link>
          </Box>

          <Button type='submit' fullWidth variant='contained' sx={{ mt: 1.5, borderRadius: '20px' }}>
            가입하기
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

export default AuthRegister;
