import React from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { signOutUserSuccess } from '../../redux/user/userSlice';
import { removeAuthToken } from '../../utils/jwtUtils';
import { setAlert } from '../../redux/alert/alertSlice';
import Logo from './../../Logo.png';

import { Box, Button, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material';
import { routes } from './utils';
import { useTranslation } from 'react-i18next';
import { resetDevice } from '../../redux/device/deviceSlice';

function Sidebar({ onSidebarVisible, onToggleSidebar }) {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { currentUser } = useSelector((state) => state.user);

  const handleCloseSidebar = () => {
    if (onSidebarVisible && window.innerWidth <= 1440) {
      onToggleSidebar(false);
    }
  };

  const handleLogout = () => {
    dispatch(signOutUserSuccess());
    removeAuthToken();
    dispatch(resetDevice());
    dispatch(setAlert({ type: 'success', message: t('sucLogoutMessage') }));
    navigate('/login');
  };

  return (
    <Box
      sx={{
        width: 256,
        minHeight: '100vh',
        borderRight: '1px solid #e0e0e0',
        backgroundColor: 'white',
        position: 'relative',
        '@media (max-width: 1440px)': {
          position: 'absolute',
          zIndex: 10000,
        },
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'start', mx: 2, mt: 1.12, mb: 0.5, height: 52 }}>
        <img src={Logo} alt='Logo' />
      </Box>
      <Box
        sx={{
          p: 1.6,
          backgroundColor: 'rgba(239, 246, 255, 1)',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Box>
          <Typography variant='subtitle1' fontWeight='bold'>
            {t('username')}
          </Typography>
          <Typography variant='body2'>{currentUser?.name}</Typography>
        </Box>
        <Button size='small' color='primary' variant='text' sx={{ fontWeight: 'bold' }} onClick={handleLogout}>
          {t('logout')}
        </Button>
      </Box>

      <List>
        {routes.map((route, i) => (
          <ListItem
            key={i}
            disablePadding
            sx={{
              backgroundColor: location.pathname === route.to ? '#DADADA' : 'inherit',
            }}
          >
            <ListItemButton component={Link} to={route.to} onClick={handleCloseSidebar}>
              <ListItemIcon>{route.icon}</ListItemIcon>
              <ListItemText primary={t(route.text)} />
            </ListItemButton>
          </ListItem>
        ))}
      </List>
    </Box>
  );
}

export default Sidebar;
