import React, { useEffect } from 'react';
import { MapContainer, TileLayer, Marker, Popup, useMap } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import { Box } from '@mui/material';
import L from 'leaflet';

// Fix default icon issue with Leaflet
import iconRetinaUrl from 'leaflet/dist/images/marker-icon-2x.png';
import iconUrl from 'leaflet/dist/images/marker-icon.png';
import shadowUrl from 'leaflet/dist/images/marker-shadow.png';
import { useTranslation } from 'react-i18next';

L.Icon.Default.mergeOptions({
  iconRetinaUrl,
  iconUrl,
  shadowUrl,
});

// Component to handle flyTo behavior
const FlyToDevice = ({ selectedDeviceLocation }) => {
  const map = useMap(); // Get access to the map

  useEffect(() => {
    if (selectedDeviceLocation) {
      map.flyTo(selectedDeviceLocation, 16, { duration: 1 });
    }
  }, [selectedDeviceLocation, map]);

  return null;
};

const FacilityMap = ({ devices = [], selectedDeviceLocation }) => {
  const { t } = useTranslation();

  // Check if devices array is empty
  if (devices.length === 0) {
    return null;
  }

  const mapCenter = [Number(devices[0].latitude), Number(devices[0].longitude)];

  return (
    <Box sx={{ height: '100%', width: '100%', position: 'relative' }}>
      <MapContainer center={mapCenter} zoom={15} style={{ height: '100%', width: '100%' }} attributionControl={false}>
        <TileLayer
          url='https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png'
          attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        />

        {/* Fly to the selected device's location */}
        {selectedDeviceLocation && <FlyToDevice selectedDeviceLocation={selectedDeviceLocation} />}

        {/* Render multiple markers */}
        {devices.map((device, i) => {
          const lat = Number(device?.latitude);
          const lng = Number(device?.longitude);

          // Check for valid lat/lng values before rendering markers
          if (!isNaN(lat) && !isNaN(lng)) {
            return (
              <Marker key={i} position={[lat, lng]}>
                <Popup>
                  {device?.nodeName} {t('coordinates')} <br />
                  {t('lat')}: {lat} <br />
                  {t('lng')}: {lng} <br />
                </Popup>
              </Marker>
            );
          }

          return null; // Skip invalid markers
        })}
      </MapContainer>
    </Box>
  );
};

export default FacilityMap;
