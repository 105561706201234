import PropTypes from 'prop-types';

// material-ui
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import MainCard from './MainCard';
import { fontStyle } from '../pages/dashboard/utils';
import { t } from 'i18next';

export default function OverviewBox({ data }) {

  return data.map((temp, i) => (
    <Grid key={i} item xs={12} sm={6} md={4}>
      <MainCard contentSX={{ p: 2.25 }}>
        <Typography
          sx={{
            ...fontStyle,
          }}
          variant='h6'
          color='text.secondary'
        >
          {t(temp.title)}
        </Typography>
        <Grid container alignItems='center'>
          <Grid item>
            <Typography
              variant='h4'
              color='inherit'
              sx={{
                ...fontStyle,
              }}
            >
              {temp.value}
            </Typography>
          </Grid>
        </Grid>
      </MainCard>
    </Grid>
  ));
}

OverviewBox.propTypes = {
  title: PropTypes.string,
  count: PropTypes.string,
};
