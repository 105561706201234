import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Link from '@mui/material/Link';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useState } from 'react';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import { Alert, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function AuthForgetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const { t } = useTranslation();
  const [formData, setFormData] = useState({
    email: '',
  });
  const [errorMessage, setErrorMessages] = useState({
    email: '',
    general: '',
  });

  // Function to handle onChange for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setErrorMessages({});
    const origin = process.env.REACT_APP_URL;
    try {
      const res = await fetch(
        `${process.env.REACT_APP_SERVER_URL}/auth/forget-password?email=${formData.email}&origin=${origin}`,
        {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      const data = await res.json();

      if (!res.ok) {
        switch (data.message) {
          case 'noneRegisteredEmailMessage':
            setErrorMessages((prev) => ({ ...prev, email: data.message }));
            break;
          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        setLoading(false);
        return;
      }

      dispatch(
        openPopup({
          title: '비밀번호를 재설정하세요!',
          message: data.message,
        })
      );
    } catch (err) {
      return;
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 64,
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ mt: 1, mb: 4, fontWeight: 'bold', fontSize: '1.1rem' }}>
            스마트 간판 관리 통합 플랫폼
          </Typography>
          <Typography level='body-sm'>새로운 비밀번호를 위해 이메일을 입력 해주세요.</Typography>
          {errorMessage.general && (
            <Stack sx={{ width: '100%', mt: 1 }}>
              <Alert severity='error'>{t(errorMessage.general)}</Alert>
            </Stack>
          )}
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              id='email'
              label='이메일 주소'
              name='email'
              autoComplete='email'
              onChange={handleChange}
              autoFocus
              error={!!errorMessage.email}
              helperText={errorMessage.email ? t(errorMessage.email) : ''}
            />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, borderRadius: '20px' }}>
              계속하기
            </Button>

            <Link href='/register' variant='body2'>
              {'신규 사용자이신가요? 가입하기'}
            </Link>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthForgetPassword;
