import Button from '@mui/material/Button';
import { Visibility as VisibilityIcon, VisibilityOff as VisibilityOffIcon } from '@mui/icons-material';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Logo from '../../Logo.png';
import { useState } from 'react';
import { openPopup } from '../../redux/popup/popupSlice';
import { setAlert } from '../../redux/alert/alertSlice';
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Spinner from '../../components/Spinner';
import { useTranslation } from 'react-i18next';
import { Alert, IconButton, InputAdornment, Stack } from '@mui/material';

// TODO remove, this demo shouldn't need to reset the theme.
const defaultTheme = createTheme();

function AuthResetPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const { token } = useParams();
  const [loading, setLoading] = useState(false);

  const [formData, setFormData] = useState({
    password: '',
    passwordConfirm: '',
  });
  const [errorMessage, setErrorMessages] = useState({
    password: '',
    passwordConfirm: '',
  });

  const [showPassword, setShowPasswords] = useState({
    password: false,
    passwordConfirm: false,
  });

  const togglePasswordShow = (type) => {
    if (type === 'password') setShowPasswords((prev) => ({ ...prev, password: !prev.password }));
    if (type === 'passwordConfirm') setShowPasswords((prev) => ({ ...prev, passwordConfirm: !prev.passwordConfirm }));
  };

  // Function to handle onChange for text fields
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true); // Start loading
    setErrorMessages({});
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          newPassword: formData.password,
          newPasswordConfirm: formData.passwordConfirm,
          token: token,
        }),
      });

      const data = await res.json();
      if (!res.ok) {
        setLoading(false);
        switch (data.message) {
          case 'invalidPasswordMessage':
          case 'sizePasswordMessage':
            setErrorMessages((prev) => ({ ...prev, password: data.message }));
            break;
          case 'newPassAndReconfirmPassNoMatchMessage':
            setErrorMessages((prev) => ({ ...prev, passwordConfirm: data.message }));
            break;
          default:
            setErrorMessages((prev) => ({ ...prev, general: data.message }));
            break;
        }
        return;
      }
      dispatch(setAlert({ type: 'success', message: data.message }));
      navigate('/login');
    } catch (err) {
      console.error(err.message);
      return;
    } finally {
      setLoading(false); // Stop loading
    }
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Container component='main' maxWidth='xs'>
        <CssBaseline />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '100vh',
          }}
        >
          {loading && <Spinner />}
          <Box
            component='img'
            sx={{
              height: 64,
            }}
            alt='Logo'
            src={Logo}
          />
          <Typography variant='h1' sx={{ mt: 1, mb: 4, fontWeight: 'bold', fontSize: '1.1rem' }}>
            스마트 간판 관리 통합 플랫폼
          </Typography>
          <Typography level='body-sm'>새로운 비밀번호와 비밀번호 확인을 입력해 주세요.</Typography>
          {errorMessage.general && (
            <Stack sx={{ width: '100%', mt: 1 }}>
              <Alert severity='error'>{t(errorMessage.general)}</Alert>
            </Stack>
          )}
          <Box component='form' onSubmit={handleSubmit} sx={{ mt: 1 }}>
            <TextField
              margin='normal'
              required
              fullWidth
              name='password'
              label='비밀번호'
              id='password'
              onChange={handleChange}
              autoFocus
              error={!!errorMessage.password}
              helperText={errorMessage.password ? t(errorMessage.password) : ''}
              type={showPassword.password ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password' edge='end' onClick={() => togglePasswordShow('password')}>
                      {showPassword.password ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <TextField
              margin='normal'
              required
              fullWidth
              name='passwordConfirm'
              label='비밀번호'
              id='passwordConfirm'
              onChange={handleChange}
              error={!!errorMessage.passwordConfirm}
              helperText={errorMessage.passwordConfirm ? t(errorMessage.passwordConfirm) : ''}
              type={showPassword.passwordConfirm ? 'text' : 'password'}
              InputProps={{
                endAdornment: (
                  <InputAdornment position='end'>
                    <IconButton
                      aria-label='toggle password'
                      edge='end'
                      onClick={() => togglePasswordShow('passwordConfirm')}
                    >
                      {showPassword.passwordConfirm ? <VisibilityIcon /> : <VisibilityOffIcon />}
                    </IconButton>
                  </InputAdornment>
                ),
              }}
            />

            <Button type='submit' fullWidth variant='contained' sx={{ mt: 3, mb: 2, borderRadius: '20px' }}>
              계속하기
            </Button>
          </Box>
        </Box>
      </Container>
    </ThemeProvider>
  );
}

export default AuthResetPassword;
