import React, { useEffect, useState } from 'react';
import mqtt from 'mqtt';
import FacilityMap from './components/FacilityMap';
import { Box, Stack, Typography } from '@mui/material';
import { fontStyle } from '../dashboard/utils';
import BacteriaMonitoring from './components/BacteriaMonitoring';
import MainCard from '../../components/MainCard';
import { useTranslation } from 'react-i18next';
import DevicesTable from './components/DevicesTable';
import { getAuthToken } from '../../utils/jwtUtils';
import { setAlert } from '../../redux/alert/alertSlice';
import { useDispatch } from 'react-redux';

function Monitoring() {
  const token = getAuthToken();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [devices, setDevices] = useState([]);
  const [client, setClient] = useState(null);
  const [selectedDeviceLocation, setSelectedDeviceLocation] = useState(null);

  const handleSelectDevice = (device) => {
    setSelectedDeviceLocation([Number(device.latitude), Number(device.longitude)]);
  };

  // Function to publish MQTT message
  const handlePublish = (content) => {
    if (client && client.connected) {
      client.publish(`/JUDE/Command/${content.macAddress}/`, JSON.stringify(content?.publishingMessage), (err) => {
        if (err) {
          console.error('Publish error:', err);
        } else {
          const message = content.publishingMessage.Value ? t('device_on') : t('device_off');
          dispatch(setAlert({ type: 'success', message }));
        }
      });
    }
  };

  // Fetch devices from the API
  const getDevices = async () => {
    try {
      const res = await fetch(`${process.env.REACT_APP_SERVER_URL}/devices/all`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
        },
      });

      if (!res.ok) {
        const errorData = await res.json();
        console.error('Error:', errorData);
        return;
      }

      const data = await res.json();

      // Initialize devices with empty message data
      const devicesWithMessages = data.map((device) => ({
        ...device,
        messageData: null, // Placeholder for message data from MQTT
        controlData: null, // Placeholder for control data
      }));

      setDevices(devicesWithMessages);
    } catch (error) {
      console.error('Fetch error:', error);
    }
  };

  // Update a specific device's message or control data based on its macAddress
  const updateDeviceData = (macAddress, message, type) => {
    setDevices((prevDevices) =>
      prevDevices.map((device) =>
        device.macAddress === macAddress
          ? {
            ...device,
            [`${type}Data`]: message, // Dynamically update either 'messageData' or 'controlData'
          }
          : device
      )
    );
  };

  // MQTT Client setup
  useEffect(() => {
    const mqttClient = mqtt.connect(process.env.REACT_APP_MQTT_ADDRESS, {
      username: process.env.REACT_APP_MQTT_USERNAME,
      password: process.env.REACT_APP_MQTT_PASSWORD,
    });

    setClient(mqttClient);

    mqttClient.on('connect', () => {
      mqttClient.subscribe(`/JUDE/Delivery/+/`);
      mqttClient.subscribe(`/JUDE/Event/+/`);
      mqttClient.subscribe(`/JUDE/Control/+/`);
    });

    mqttClient.on('message', (topic, payload) => {
      const message = JSON.parse(payload);
      const macAddress = topic.split('/')[3]; // Extract macAddress from the topic

      if (topic.includes('Control')) {
        updateDeviceData(macAddress, message, 'control');
      } else if (topic.includes('Delivery')) {
        updateDeviceData(macAddress, message, 'delivery');
      } else if (topic.includes('Event')) {
        updateDeviceData(macAddress, message, 'event');
      }
    });

    return () => {
      if (mqttClient) mqttClient.end();
    };
  }, []);

  useEffect(() => {
    getDevices();
  }, []);

  return (
    <Box sx={{ display: 'flex', gap: 2.5, flexDirection: { xs: 'column', lg: 'row' } }}>
      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        {/* MAP */}
        <MainCard>
          <Box sx={{ height: 400 }}>
            <FacilityMap devices={devices} selectedDeviceLocation={selectedDeviceLocation} />
          </Box>
        </MainCard>

        {/* TABLE */}
        <Box>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', mt: 2.5, mb: 1, gap: 2 }}>
            <Typography
              sx={{
                ...fontStyle,
                display: 'block',
                lineHeight: '1',
                minWidth: 'max-content',
              }}
              variant='h5'
            >
              {t('devices_list')}
            </Typography>
            <Typography
              sx={{
                ...fontStyle,
                display: 'inline-block',
                lineHeight: '1',
                fontWeight: '400',
                fontSize: { xs: 13, sm: 16 },
              }}
              variant='body1'
            >
              {t('devices_data_pending')}
            </Typography>
          </Box>
          <MainCard content={false}>
            <DevicesTable devices={devices} onPublish={handlePublish} onSelectDevice={handleSelectDevice} />
          </MainCard>
        </Box>
      </Stack>

      {/* Monitoring */}
      <Stack sx={{ width: { xs: '100%', lg: '50%' } }}>
        <BacteriaMonitoring devices={devices} />
      </Stack>
    </Box>
  );
}

export default Monitoring;
