import React from 'react';
import { Box, Stack, Typography } from '@mui/material';
import MainCard from '../../../components/MainCard';
import { fontStyle } from '../../dashboard/utils';
import { useTranslation } from 'react-i18next';
import { sensors } from '../utils';

function BacteriaMonitoring({ devices }) {
  const { t } = useTranslation();

  return (
    <Stack sx={{ maxHeight: '99vh', overflowY: 'scroll' }}>
      {devices.map((device, deviceId) => (
        <Box
          key={deviceId}
          sx={{
            border: '1px solid #e0e0e0',
            borderRadius: 2,
            mb: deviceId === devices.length - 1 ? 0 : 2,
            bgcolor: '#e0e0e0',
          }}
        >
          <Box sx={{ ...fontStyle, fontSize: '0.9rem', mt: 0.8, mb: 0.6, mx: 1 }}>{device?.nodeName}</Box>
          <Box sx={{ width: '100%', display: 'flex', flexWrap: 'wrap' }}>
            {sensors.map((sensor, i) => (
              <MainCard
                key={i}
                sx={{
                  flex: { xs: '1 1 50%', sm: '1 1 33.3%' },
                  boxSizing: 'border-box',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  py: { xs: 1, sm: 2.5 },
                }}
                content={false}
              >
                <Typography sx={{ ...fontStyle, fontSize: '0.8rem', fontWeight: 'bold' }} variant='h5'>
                  {sensor.sensorName}
                </Typography>
                <Typography sx={{ ...fontStyle, fontSize: '1.2rem' }} variant='h5'>
                  {device?.deliveryData ? device?.deliveryData?.[sensor.sensorData] : "-"}
                </Typography>
              </MainCard>
            ))}
          </Box>
        </Box>
      ))}
    </Stack>
  );
}

export default BacteriaMonitoring;
