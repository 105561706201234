import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  LinearProgress,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import PowerIcon from '@mui/icons-material/Power';
import PowerOffIcon from '@mui/icons-material/PowerOff';
import ReplayIcon from '@mui/icons-material/Replay';
import { useTranslation } from 'react-i18next';

function DevicesTableHead({ order, orderBy }) {
  const { t } = useTranslation();
  const headCells = [
    { id: 'device_name', label: t('device_name') },
    { id: 'fire', label: t('fire_status') },
    { id: 'fall', label: t('fall_status') },
    { id: 'temperature', label: t('temperature') },
    { id: 'power', label: t('power_status') },
    { id: 'control', label: t('power_control') },
  ];

  return (
    <TableHead>
      <TableRow>
        {headCells.map((headCell) => (
          <TableCell align="center" sx={{ fontWeight: 'bold', px: 0, py: 1.2 }} key={headCell.id}>
            {headCell.label}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

DevicesTableHead.propTypes = {
  order: PropTypes.any,
  orderBy: PropTypes.string,
};

export default function DevicesTable({ devices, onPublish, onSelectDevice }) {
  const order = 'asc';
  const orderBy = 'id';
  const { t } = useTranslation();

  // State to track fire and fall status per device
  const [fireStatus, setFireStatus] = useState({});
  const [fallStatus, setFallStatus] = useState({});

  useEffect(() => {
    let initialFireStatus = {};
    let initialFallStatus = {};

    devices.forEach((device, i) => {
      if (device?.eventData?.EventType === 'Fire' && device?.eventData?.Value === 'True') {
        initialFireStatus[i] = true;
      }
      if (device?.eventData?.EventType === 'Fall' && device?.eventData?.Value === 'True') {
        initialFallStatus[i] = true;
      }
    });

    setFireStatus(initialFireStatus);
    setFallStatus(initialFallStatus);
  }, [devices]);

  // Function to handle publish actions for Fall, Fire, and Relay
  const handlePublish = (macAddress, currentValue, commandType) => {
    onPublish({
      macAddress,
      publishingMessage: {
        CommandType: commandType,
        Value: currentValue === 'True' || currentValue === 'true' ? false : true,
      },
    });
  };

  // Toggle Fire status for a specific row
  const toggleFireStatus = (index) => {
    const updatedDevice = { ...devices[index] };
    updatedDevice.eventData.Value = 'False';

    setFireStatus((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };

  // Toggle Fall status for a specific row
  const toggleFallStatus = (index) => {
    const updatedDevice = { ...devices[index] };
    updatedDevice.eventData.Value = 'False';

    setFallStatus((prevState) => ({
      ...prevState,
      [index]: false,
    }));
  };
  // 434
  return (
    <Box sx={{ width: '100%', }}>
      <TableContainer sx={{ width: '100%', overflow: 'auto', maxHeight: 434 }}>
        <Table stickyHeader sx={{ minWidth: 600, }} size="small" aria-labelledby="table">
          <DevicesTableHead order={order} orderBy={orderBy} />
          <TableBody>
            {devices.map((device, index) => (
              <TableRow size='small' hover key={index} onClick={() => onSelectDevice(device)}>
                <TableCell sx={{ p: 0 }} align="center">{device.nodeName}</TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  {fireStatus[index] ? (
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                      <Typography sx={{ color: 'red' }}>{t('fire_status')}</Typography>
                      <ReplayIcon sx={{ fontSize: 24, cursor: 'pointer', color: 'green' }} onClick={() => toggleFireStatus(index)} />
                    </Box>
                  ) : (
                    <Typography>{t('event_normal')}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  {fallStatus[index] ? (
                    <Box sx={{ display: 'flex', gap: 1, justifyContent: 'center' }}>
                      <Typography sx={{ color: 'red' }}>{t('fall_alarm')}</Typography>
                      <ReplayIcon sx={{ fontSize: 24, cursor: 'pointer', color: 'green' }} onClick={() => toggleFallStatus(index)} />
                    </Box>
                  ) : (
                    <Typography>{t('event_normal')}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  {device?.deliveryData?.Temperature ? (
                    <Box>
                      {device?.deliveryData?.Temperature}℃
                      <LinearProgress
                        variant="determinate"
                        value={device?.deliveryData?.Temperature ? device?.deliveryData?.Temperature * 1 : 0}
                        sx={{
                          height: 8,
                          borderRadius: 5,
                          backgroundColor: '#f0f0f0',
                          '& .MuiLinearProgress-bar': { backgroundColor: 'blue' },
                        }}
                      />
                    </Box>
                  ) : (
                    <Typography>{t('pending')}</Typography>
                  )}
                </TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    {device?.deliveryData?.Relay === 'True' ? (
                      <PowerIcon sx={{ fontSize: 20, color: 'red' }} />
                    ) : (
                      <PowerOffIcon sx={{ fontSize: 20 }} />
                    )}
                    <Typography>{device?.deliveryData?.Relay === 'True' ? 'ON' : 'OFF'}</Typography>
                  </Box>
                </TableCell>
                <TableCell sx={{ p: 0 }} align="center">
                  <Switch
                    checked={device?.deliveryData?.Relay === 'True'}
                    onClick={() => handlePublish(device?.macAddress, device?.deliveryData?.Relay, 'Relay')}
                    inputProps={{ 'aria-label': 'controlled' }}
                    disabled={!device?.deliveryData || device?.eventData?.Value === 'True'}
                  />
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </Box>
  );
}

DevicesTable.propTypes = {
  devices: PropTypes.array.isRequired,
  onPublish: PropTypes.func.isRequired,
  onSelectDevice: PropTypes.func.isRequired,
};
