import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  currentDevice: {},
};

const deviceSlice = createSlice({
  name: 'device',
  initialState,
  reducers: {
    setDevice: (state, action) => {
      state.currentDevice = action.payload;
    },
    resetDevice: (state) => {
      state.currentDevice = {};
    },
  },
});

export const { setDevice, resetDevice } = deviceSlice.actions;
export default deviceSlice.reducer;
