import React, { useState } from 'react';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import SelectMenu from '../SelectMenu';
import { Button } from '@mui/material';
import LanguagesMenu from '../LanguagesMenu';
import { HiMiniBars3BottomRight, HiMiniBars4 } from 'react-icons/hi2';
import { useLocation } from 'react-router-dom';

function Header({ onToggleSidebar, onSidebarVisible }) {
  const location = useLocation();

  const handleCloseSidebar = () => {
    if (window.innerWidth <= 1440 && onSidebarVisible) {
      onToggleSidebar(false);
    }
  }

  return (
    <Box onClick={handleCloseSidebar}>
      <AppBar position="static" sx={{ bgcolor: '#ffffff', borderBottom: '1px solid #e0e0e0' }}>
        <Toolbar>
          <Button
            onClick={() => onToggleSidebar(!onSidebarVisible)}
            sx={{
              borderRadius: 1, minWidth: 'max-content', color: '#000000', '&:hover': {
                backgroundColor: '#e0e0e0',
              }
            }}
          >
            {onSidebarVisible ? <HiMiniBars3BottomRight size={24} /> : <HiMiniBars4 size={24} />}
          </Button>

          {location.pathname == "/dashboard" && <SelectMenu />}

          {/* Needed for pushing elements to right side */}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }} />

          <LanguagesMenu />

        </Toolbar>
      </AppBar>
    </Box>
  );
}

export default Header;
