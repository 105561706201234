import React, { useEffect, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import SensorChart from '../components/SensorChart';
import { Box, Typography } from '@mui/material';
import 'swiper/css';
import 'swiper/css/navigation';
import './swiper.css';
import { Navigation } from 'swiper/modules';
import MainCard from '../../../components/MainCard';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { sensorElements } from '../utils';

export default function DashboardCarousel({ data }) {
  const { t } = useTranslation();
  const [graphData, setGraphData] = useState({});
  const breakpoints = {
    0: {
      slidesPerView: 1,
    },
    700: {
      slidesPerView: 2,
    },
    1024: {
      slidesPerView: 3,
    },
    1800: {
      slidesPerView: 5,
    },
    3000: {
      slidesPerView: 6,
    },
  };

  useEffect(() => {
    // Initialize the structure for datasets
    const formattedData = {};

    // Format data from the response
    data.forEach((entry) => {
      const dateString = moment(`${entry.day} ${entry.hour}`).format('YYYY/MM/DD HH:mm');
      sensorElements.forEach(({ sensor }) => {

        if (!formattedData[sensor]) {
          formattedData[sensor] = { labels: [], data: [] };
        }

        // Push date string to labels if not already present
        if (!formattedData[sensor].labels.includes(dateString)) {
          formattedData[sensor].labels.push(dateString);
        }

        // Push corresponding data value, only if valid
        formattedData[sensor].data.push(entry[sensor] >= 0 ? entry[sensor] : null);
      });
    });

    setGraphData(formattedData);
  }, [data]);

  return (
    <Swiper
      spaceBetween={10}
      breakpoints={breakpoints}
      loop={true}
      navigation={true}
      pagination={true}
      modules={[Navigation]}
    >
      {sensorElements.map((slide, index) => (
        <SwiperSlide key={index}>
          <MainCard>
            <Typography sx={{ textAlign: 'center' }} variant='body1'>
              {slide.name}
            </Typography>
            {graphData[slide.sensor]?.labels ? (
              <SensorChart
                data={{
                  labels: graphData[slide.sensor].labels,
                  datasets: [{ label: t('avg'), data: graphData[slide.sensor].data }],
                }}
              />
            ) : (
              <Box sx={{ height: 130, display: 'flex', flexDirection: 'column', justifyItems: 'center' }}>
                <Typography variant='body2' sx={{ textAlign: 'center', my: 'auto' }}>
                  {t('noData')}
                </Typography>
              </Box>
            )}
          </MainCard>
        </SwiperSlide>
      ))}
    </Swiper>
  );
}
