export const floors = [1, 2, 3, 4];
export const sensors = [
  {
    sensorName: '온도 Temperature (℃)',
    sensorData: 'Temperature',
  },
  {
    sensorName: '습도 Humidity (%)',
    sensorData: 'Humidity',
  },
  {
    sensorName: '미세먼지 PM10 (mg/m3)',
    sensorData: 'PM10',

  },
  {
    sensorName: '초미세먼지 PM2.5 (mg/m3)',
    sensorData: 'PM25',
  },
  {
    sensorName: '오존 O3 (ppm)',
    sensorData: 'O3',
  },
  {
    sensorName: '일산화탙소 CO (ppm)',
    sensorData: 'CO',
  },
  {
    sensorName: '이산화탄소 CO2 (ppm)',
    sensorData: 'CO2',
  },
  {
    sensorName: '폼알데히드 CH2O (ppm)',
    sensorData: 'CH2O',
  },
  {
    sensorName: '이산화질소 NO2 (ppm)',
    sensorData: 'NO2',
  },
];
export const MapCenter = [37.568171, 126.64616];
export const MapMarkers = [{ position: [37.568171, 126.64616], popupText: 'Marker in Center' }];

