import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
import moment from 'moment';

// material-ui
import { useTheme } from '@mui/material/styles';

// third-party
import ReactApexChart from 'react-apexcharts';
import { useTranslation } from 'react-i18next';

// Initial chart options
const initialOptions = {
  chart: {
    height: 340,
    type: 'area',
    toolbar: {
      show: false,
    },
  },
  noData: {
    text: 'No data available', // Add a no data message here
    align: 'center',
    verticalAlign: 'middle',
    style: {
      color: '#888',
      fontSize: '16px',
    },
  },
  dataLabels: {
    enabled: false,
  },
  legend: {
    show: false,
  },
  stroke: {
    curve: 'smooth',
    width: 2,
  },
  grid: {
    strokeDashArray: 0,
  },
  xaxis: {
    categories: [],
    labels: {
      show: false,
      style: {
        colors: [],
        fontSize: '12px',
      },
    },
  },
  yaxis: {
    labels: {
      style: {
        colors: [],
      },
    },
  },
};

// ==============================|| BACTERIAL LEVEL CHART ||============================== //

export default function BacterialLevelChart({ slot, data }) {
  const theme = useTheme();
  const { t } = useTranslation();

  const { primary, secondary } = theme.palette.text;
  const line = theme.palette.divider;

  const [options, setOptions] = useState(initialOptions);
  const [series, setSeries] = useState([]);


  useEffect(() => {
    if (!data || data.length === 0) {
      // Clear previous data and set the no-data message
      setSeries([]);
      setOptions((prevState) => ({
        ...prevState,
        xaxis: {
          ...prevState.xaxis,
          categories: [], // Clear previous categories
        },
        noData: {
          text: t('noDataForDates'),
        },
      }));
      return;
    }

    // Format dates and update series with temperature data
    const tempData = data.map((entry) => entry.avgTemperature);
    const tempDates = data.map((entry) => moment(`${entry.day} ${entry.hour}`).format('YYYY/MM/DD HH:mm'));

    setSeries([
      {
        name: t('temperature'),
        data: tempData,
      },
    ]);

    // Update x-axis categories dynamically
    setOptions((prevState) => ({
      ...prevState,
      xaxis: {
        ...prevState.xaxis,
        categories: tempDates,
        labels: {
          style: {
            colors: new Array(tempDates.length).fill(secondary),
            fontSize: '12px',
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
      },
    }));
  }, [data]);

  useEffect(() => {
    setOptions((prevState) => ({
      ...prevState,
      colors: [theme.palette.primary.main, theme.palette.primary[700]],
      xaxis: {
        ...prevState.xaxis,
        labels: {
          show: false,
          style: {
            colors: new Array(prevState.xaxis.categories.length).fill(secondary),
            fontSize: '12px',
          },
        },
      },
      yaxis: {
        labels: {
          style: {
            colors: [secondary],
          },
        },
      },
      grid: {
        borderColor: line,
      },
    }));
  }, [primary, secondary, line, theme, slot]);

  return <ReactApexChart options={options} series={series} type='area' height={380} />;
}

BacterialLevelChart.propTypes = {
  slot: PropTypes.string,
  data: PropTypes.array.isRequired,
};
